import Logo from "assets/images/logo_triangletech.jpg";
import Nav from "components/common/Nav/Nav";
import SwitchLangButton from "components/common/SwitchLangButton/SwitchLangButton";
import { useBreakpoints } from "hooks/useBreakpoint";
import { t } from "i18next";
import { BannerWrapper } from "./CustomStyled";

const Banner = ({ showNav, url }) => {
	const { isMobile } = useBreakpoints();

	return (
		<BannerWrapper>
			<div className="banner-background">
				<img src={url} alt="banner-background" />
			</div>
			<div className="banner-content">
				<div className="banner-content-header">
					<div className="banner-content-header-logo">
						{/* <div>
							<img src={Logo} alt="" />
						</div> */}
						{/* {!isMobile && <p>{t("hotline")} : 0926095888</p>} */}
					</div>
					<div className="banner-content-header-lang">
						<SwitchLangButton />
					</div>
				</div>
				{/* <div className="banner-content-msg">
          <p>
            <span>{t("going to perfect")}</span> <br />
          </p>
        </div> */}
				<div className="banner-content-link">
					{!isMobile && <p>{t("Exciting offers on")}</p>}
					<Nav collapse={showNav} />
				</div>
			</div>
			{/* <IconCallPhone /> */}
		</BannerWrapper>
	);
};

export default Banner;
