import { Popover } from "antd";
import IconFacebook from "assets/images/logo_fb.png";
import IconPhone from "assets/images/phone-call-icon.png";
import IconZalo from "assets/images/zalo-1.png";
import { IconCallPhoneWrapper } from "./CustomStyled";

const IconCallPhone = () => {
	return (
		<IconCallPhoneWrapper>
			<a
				className="zalo-call"
				href="https://zalo.me/605827821545658699"
				target="_blank"
				rel="noreferrer"
			>
				<img src={IconZalo} width="50" alt="Call Now" title="Call Now" />
			</a>
			<a
				className="facebook-call"
				href="https://www.facebook.com/people/T%E1%BB%A7-%C4%91i%E1%BB%87n_Triangle-Tech/100090765010280/"
				target="_blank"
				rel="noreferrer"
			>
				<img src={IconFacebook} width="50" alt="Call Now" title="Call Now" />
			</a>
			<Popover
				placement="left"
				// title={"Phone Number"}
				content={"0926095888"}
				trigger="hover"
			>
				<a className="phone-call" href="tel:0926095888">
					<img src={IconPhone} width="50" alt="Call Now" title="Call Now" />
				</a>
			</Popover>
		</IconCallPhoneWrapper>
	);
};

export default IconCallPhone;
