import { LoadingWrapper } from "./CustomStyled";

const Loading = () => {
	return (
		// <LoadingWrapper>
		//   <section className="loading">
		//     <span className="loading__anim"></span>
		//   </section>
		// </LoadingWrapper>
		<LoadingWrapper>
			<div className="loader">
				<div className="inner one"></div>
				<div className="inner two"></div>
				<div className="inner three"></div>
			</div>
		</LoadingWrapper>
	);
};

export default Loading;
