import IconCallPhone from "components/common/IconCallPhone/IconCallPhone";
import Blog from "containers/Blog/Blog";
import BlogDetail from "containers/BlogDetail/BlogDetail";
import Hiring from "containers/Hiring/Hiring";
import Home from "containers/Home/Home";
import NotFound from "containers/NotFound/NotFound";
import { Navigate, Outlet, useRoutes } from "react-router-dom";
import "./App.less";

const routes = [
	{ path: "/", element: <Home /> },
	{ path: "/blog", element: <Blog /> },
	{ path: "/blog/:slug", element: <BlogDetail /> },
	{ path: "/hiring", element: <Hiring /> },
	{ path: "/404", element: <NotFound /> },
	{ path: "*", element: <Navigate replace to="404" /> },
];

function App() {
	const routing = useRoutes(routes);
	return (
		<>
			{/* <Routes>
        <Route path="/" element={<Home />} />
        <Route path="blog" element={<Blog />} />
        <Route path="blog/:slug" element={<BlogDetail />} />
        <Route path="hiring" element={<Hiring />} />
        <Route path="/404" element={<NotFound />} />
        <Route path="*" element={<Navigate replace to="/404" />} />
      </Routes>
      <Outlet /> */}

			{routing}
			<IconCallPhone />
			<Outlet />
		</>
	);
}

export default App;
